<template>
  <div class="container">
    <!-- Display Existing Configuration -->
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">
          <!-- <img src="https://customer.vitelglobal.com/assets/img/logo.svg" style="width:10%" />&nbsp;&nbsp; -->
          KF-Mail Configuration
        </h3>
      </div>
      <!-- No Data Section -->
      <el-row
        :gutter="12"
        v-if="
          !showTextArea && MailAccount && Object.keys(MailAccount).length === 0
        "
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container mt-1 mb-5 pl-1 pr-1 pt-1 pb-5"
        >
          <div class="no-data" style="text-align: center">
            <img
              src="https://email.firm.in/wp-content/webpc-passthru.php?src=https://email.firm.in/wp-content/uploads/2021/06/top-10-email-service-providers.png&nocache=1g"
              style="width: 80%; border-radius: 15rem"
              alt="No Data"
            />
            <p>
              There are currently no configurations available. Click 'Add' to
              create a configuration.
            </p>
            <div>
              <el-button type="primary" @click="createConfiguration"
                >Add Configuration</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row
        v-if="
          !showTextArea && MailAccount && Object.keys(MailAccount).length > 0
        "
        class="configurations-row"
      >
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="mt-1 mb-5 pt-1 pb-5 pl-1 pr-1"
        >
          <h3 class="section-title"></h3>
          <div style="padding: 0 0.55rem">
            <table id="customers">
              <tr>
                <th>Service</th>
                <td>{{ MailAccount.service.toUpperCase() }}</td>
              </tr>
              <tr v-if="MailAccount.connectionType === 'oauth'">
                <th>Connection Type</th>
                <td>{{ MailAccount.connectionType.toUpperCase() }}</td>
              </tr>
              <tr v-if="MailAccount.connectionType === 'oauth'">
                <th>Domain</th>
                <td>{{ MailAccount.domain || "N/A" }}</td>
              </tr>
              <tr v-if="MailAccount.connectionType === 'imap'">
                <th>Host</th>
                <td>{{ MailAccount.imapConfig?.host || "N/A" }}</td>
              </tr>
              <tr v-if="MailAccount.connectionType === 'imap'">
                <th>Username</th>
                <td>{{ MailAccount.imapConfig?.username || "N/A" }}</td>
              </tr>
              <tr v-if="MailAccount.connectionType === 'imap'">
                <th>Port</th>
                <td>{{ MailAccount.imapConfig?.port || "N/A" }}</td>
              </tr>
              <tr>
                <th>Inbox Visibility</th>
                <td>
                  <el-switch
                    v-model="MailAccount.isInboxVisible"
                    @change="updateStatus"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  ></el-switch>
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <el-switch
                    v-model="MailAccount.isActive"
                    @change="updateStatus"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  ></el-switch>
                </td>
              </tr>
              <tr>
                <th>Display in Menu</th>
                <td>
                  <el-switch
                    v-model="MailAccount.isInMenu"
                    @change="updateStatus"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  ></el-switch>
                </td>
              </tr>
              <tr>
                <th>Actions</th>
                <td>
                  <el-button
                    type="danger"
                    size="mini"
                    @click="removeIntegration(MailAccount._id)"
                  >
                    Remove
                  </el-button>
                </td>
              </tr>
            </table>
          </div>
        </el-col>
      </el-row>

      <!-- Form Box -->
      <el-row v-if="showTextArea" class="configurations-row">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="mt-1 mb-5 pt-1 pb-5 pl-1 pr-1"
        >
          <div class="form-box">
            <h3 class="form-title">Connect to Email Service</h3>
            <hr />
            <br />
            <form @submit.prevent="handleSubmit">
              <div class="radio-buttons">
                <label class="radio-label">
                  <input type="radio" v-model="connectionType" value="oauth" />
                  O-Auth
                </label>
                <label class="radio-label">
                  <input type="radio" v-model="connectionType" value="imap" />
                  IMAP
                </label>
              </div>

              <!-- OAuth Form -->
              <div v-if="connectionType === 'oauth'" class="oauth-form">
                <div class="form-group">
                  <label for="service">Select Service</label>
                  <select
                    v-model="selectedService"
                    id="service"
                    class="form-control"
                  >
                    <option value="">Select a service</option>
                    <option value="gmail">📮 Gmail</option>
                    <option value="outlook">📬 Outlook</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="domain"
                    >Domain (e.g., gmail.com, outlook.com)</label
                  >
                  <input
                    v-model="domain"
                    type="text"
                    id="domain"
                    class="form-control"
                    placeholder="Enter domain"
                    :class="{ invalid: domainInvalid }"
                    required
                  />
                  <span v-if="domainInvalid" class="error">Invalid domain</span>
                </div>
              </div>

              <!-- IMAP Form -->
              <div v-if="connectionType === 'imap'" class="imap-form">
                <div class="form-group">
                  <label for="host">Host</label>
                  <input
                    v-model="host"
                    type="text"
                    id="host"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="username">Username</label>
                  <input
                    v-model="username"
                    type="text"
                    id="username"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="password">Password</label>
                  <input
                    v-model="password"
                    type="password"
                    id="password"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="port">Port</label>
                  <input
                    v-model="port"
                    type="number"
                    id="port"
                    class="form-control"
                    required
                  />
                </div>
              </div>

              <!-- Submit Button -->
              <button
                type="submit"
                class="submit-btn"
                :disabled="isSubmitDisabled"
              >
                Submit
              </button>
            </form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      MailAccount: {},
      connectionType: "", // 'oauth' or 'imap'
      selectedService: "", // 'gmail' or 'outlook'
      domain: "",
      host: "",
      username: "",
      password: "",
      port: "",
      domainInvalid: false,
      loading: false,
      showTextArea: false,
      loadingText: "Loading ...",
    };
  },
  async mounted() {
    this.getAccounts();
  },
  computed: {
    isSubmitDisabled() {
      if (this.connectionType === "oauth") {
        return !this.selectedService || !this.domain || this.domainInvalid;
      } else if (this.connectionType === "imap") {
        return !this.host || !this.username || !this.password || !this.port;
      }
      return true;
    },
    ...mapGetters("auth", [
      "getActiveWorkspace",
      "getAuthenticatedUser",
      "getWorkspaceList",
    ]),
    ...mapGetters("UserIntegrations", ["getIntegrationsList"]),
  },
  methods: {
    createConfiguration() {
      this.loading = true;
      this.loadingText = "Please wait.....";
      this.showTextArea = true;
      setTimeout(() => {
        this.loading = false;
        this.loadingText = "Please wait.....";
      }, 2000);
    },
    validateDomain() {
      console.log("Validating domain:", this.domain);
      const validDomains = ["gmail.com", "outlook.com"];
      const extractedDomain = this.domain.includes("@")
        ? this.domain.split("@")[1]
        : this.domain;
      // Validate the extracted domain
      this.domainInvalid = !validDomains.includes(extractedDomain.trim());
    },

    async handleSubmit() {
      // this.$validator.validateAll().then(async (result) => {
      //   if (result) {
      try {
        this.loading = true;
        this.loadingText = "Submitting configuration...";
        const payload = {
          connectionType: this.connectionType,
          service:
            this.connectionType === "oauth" ? this.selectedService : null,
          domain: this.connectionType === "oauth" ? this.domain : null,
          imapConfig:
            this.connectionType === "imap"
              ? {
                  host: this.host,
                  username: this.username,
                  password: this.password,
                  port: this.port,
                }
              : null,
        };
        const configuration = this.$CryptoJS.AES.encrypt(
          JSON.stringify(payload),
          "MAIL"
        ).toString();
        let data = {
          appName: "MAIL",
          clientSecret: configuration,
          isActive: true,
          isInMenu: true,
          isInboxVisible: true,
        };
        const response = await postAPICall(
          "POST",
          "/user_integrations/createIntegration",
          data
        );
        if (response && response.success) {
          this.$message.success("Configuration added successfully!");
          this.getAccounts();
          this.resetForm();
        } else {
          this.$message.error(
            "Failed to create integration: " + response.message
          );
        }
      } catch (error) {
        console.error(error);
        this.$message.error(
          "An error occurred while creating the integration."
        );
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
      //   } else {
      //     // alert('Please correct the errors.');
      //     console.log("Please correct the errors.");
      //   }
      // });
    },
    async getAccounts() {
      this.loading = true;
      this.loadingText = "Loading configurations...";
      try {
        const response = await postAPICall(
          "GET",
          "/user_integrations/list/MAIL"
        );
        if (response && response.success && response?.data?.clientSecret) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            response.data.clientSecret,
            "MAIL"
          );
          const info = JSON.parse(decrypted.toString(this.$CryptoJS.enc.Utf8));
          this.MailAccount = { ...info, ...response.data };
          this.showTextArea = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
        this.showTextArea = false;
      }
    },
    async updateStatus() {
      if (!this.MailAccount) return;
      this.loading = true;
      this.loadingText = "Updating ...";
      console.log("darfrfgtgtgt", this.MailAccount);
      try {
        let response = await postAPICall(
          "GET",
          `/user_integrations/integration/${this.MailAccount._id}/${this.MailAccount.isInMenu}/${this.MailAccount.isActive}/${this.MailAccount.isInboxVisible}`
        );
        if (response && response.success) {
          this.loading = true;
          this.loadingText = "Loading...";
          this.$message.success("KF-Mail Configuration updated!");
          setTimeout(() => {
            this.loading = false;
            this.loadingText = "Please wait.....";
            this.getAccounts();
          }, 1500);
        }
      } catch (error) {
        console.error(error);
        this.$message.error("Failed to update the configuration!");
        this.getAccounts();
      } finally {
        this.loading = false;
      }
    },
    async removeIntegration(id) {
      this.$confirm(
        "If you remove this Configuration the User Configurations will also be deleted",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.deleteConfigurations(id);
      });
    },
    async deleteConfigurations(id) {
      this.loading = true;
      const response = await postAPICall(
        "GET",
        `/user_integrations/integration/${id}`
      );
      if (response && response.success) {
        this.$message.success("Integration removed successfully!");
        this.MailAccount = {};
        this.loading = false;
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Failed to remove integration: ",
        });
      }
    },
    resetForm() {
      this.connectionType = "";
      this.selectedService = "";
      this.domain = "";
      this.host = "";
      this.username = "";
      this.password = "";
      this.port = "";
      this.domainInvalid = false;
    },
  },
  watch: {
    domain(newValue) {
      this.validateDomain(newValue);
    },
  },
};
</script>

<style scoped>
/* Container Styling */
.container {
  margin: 0 auto;
  padding: 20px;
}

/* Section Titles */
.section-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-title {
  color: #333;
}

/* Configuration Row */
.configurations-row {
  margin-bottom: 40px;
}

/* Integration Card */
.integration-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.service-name {
  font-size: 1.2em;
  color: #555;
}

.connection-type {
  font-size: 0.9em;
  color: #888;
}

.card-body p {
  margin: 5px 0;
  color: #555;
}

/* Form Box */
.form-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Radio Buttons */
.radio-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.radio-label {
  margin: 0 15px;
  font-size: 1em;
  color: #333;
  cursor: pointer;
}

.radio-label input {
  margin-right: 8px;
}

/* Form Groups */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: #409eff;
  outline: none;
}

/* Invalid Input */
.invalid {
  border-color: #f56c6c;
}

.error {
  color: #f56c6c;
  font-size: 0.9em;
  margin-top: 5px;
  display: block;
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:disabled {
  background-color: #a0cfff;
  cursor: not-allowed;
}

.submit-btn:hover:not(:disabled) {
  background-color: #66b1ff;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}
/*
background-color: #04AA6D;
color: white;
*/
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

/* Responsive Design */
@media (max-width: 600px) {
  .radio-buttons {
    flex-direction: column;
    align-items: center;
  }

  .radio-label {
    margin: 10px 0;
  }

  .integration-card {
    padding: 15px;
  }

  .form-box {
    padding: 20px;
  }
}
</style>
